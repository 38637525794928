<template>
  <div style="width: 100%;height: 100vh;">
    <template v-if="obj.imageUrl">
      <div
        v-if="equipment === 1"
        class="box"
        :style="
          `background: url('${obj.imageUrl}') no-repeat; background-size: 100% 100%;`
        "
      >
        <span
          class="icon"
          :class="isPlay ? 'play rotary' : 'noplay'"
          @click="getStop"
        ></span>
        <audio class="audio" ref="audio" :controls="isPlay" autoplay loop>
          <source :src="obj.musicUrl" type="audio/mpeg" />
        </audio>
      </div>
      <div v-else class="pc-box">
        <span class="por">
          <img :src="obj.imageUrl" class="img" />
          <span
            class="icon"
            :class="isPlay ? 'play rotary' : 'noplay'"
            @click="getStop"
          ></span>
          <audio class="audio" ref="audio" :controls="isPlay" autoplay loop>
            <source :src="obj.musicUrl" type="audio/mpeg" />
          </audio>
        </span>
      </div>
    </template>
    <template v-else>
      <div class="loading">
        <van-loading color="#1989fa" size="50px" text-size="18px" vertical
          >加载中...</van-loading
        >
      </div>
    </template>
  </div>
</template>

<script>
import { getConfigInfo } from "@/server/birthdayWishes";

export default {
  name: "H5ProjectIndex",

  data() {
    return {
      obj: {},
      isPlay: true,
      equipment: 1,
    };
  },
  watch: {
    isPlay(val) {
      if (val) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
    },
  },
  created() {
    const verfEquipment = () => {
      let sUserAgent = navigator.userAgent.toLowerCase();
      let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      let bIsMidp = sUserAgent.match(/midp/i) == "midp";
      let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      let bIsAndroid = sUserAgent.match(/android/i) == "android";
      let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
      ) {
        return 1;
      } else {
        return 2;
      }
    };
    this.equipment = verfEquipment();
    this.init();
  },
  methods: {
    init() {
      getConfigInfo().then((res) => {
        const { imageUrl, musicUrl } = res.data;
        const imageUrlStr = JSON.parse(imageUrl)[0].link;
        const musicUrlStr = JSON.parse(musicUrl)[0].link;
        this.obj = {
          imageUrl: imageUrlStr,
          musicUrl: musicUrlStr,
        };
      });
    },
    getStop() {
      this.isPlay = !this.isPlay;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: right;
  .icon {
    display: inline-block;
    width: 30px;
    height: 30px;
  }
}
.pc-box {
  display: flex;
  justify-content: center;
  .por {
    display: inline-block;
    position: relative;
    .img {
      height: 100vh;
    }
    .icon {
      display: inline-block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }
}
.play {
  background: url("../../assets/img/music.png") no-repeat;
  background-size: 100% 100%;
}
.noplay {
  background: url("../../assets/img/stopMusic.png") no-repeat;
  background-size: 100% 100%;
}
.rotary {
  animation: load 2s linear infinite;
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.audio {
  display: none;
}
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
